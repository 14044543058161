
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getMaclDataRequest, getMaclOperatorArrivalsRequest } from "../../../api/controller/StatisticsController";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Loader } from "../../../custom/components/Loader";

const Macl = () => {

    const [movementLoading, setMovementLoading] = useState(true)
    const [movementData, setMovementData] = useState([])
    const [movementYears, setMovementYears] = useState([])

    const [selectedYears, setSelectedYears] = useState([])
    const [atmSeriesData, setAtmSeriesData] = useState([])
    const [atmCategories] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])
    const [months] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])


    const [operatorLoading, setOperatorLoading] = useState(true)
    const [operatorData, setOperatorData] = useState([])
    const currentYear = new Date().getFullYear();
    const lastNYears = Array.from({length: 3}, (_, i) => currentYear - i);
    const [operatorYears] = useState(lastNYears)
    const [selectedOperatorYear, setSelectedOperatorYear] = useState({label: currentYear, value: currentYear})

    useEffect(() => {
        async function getData(){
            const response = await getMaclDataRequest();
            if(response && response.movementData) setMovementData(response.movementData);
            if(response && response.movementYears) setMovementYears(response.movementYears);
            setMovementLoading(false)
        }
        getData();
    }, [])

    useEffect(() => {
        if(selectedYears.length === 0){
            setSelectedYears(movementYears.map(y=>({label: y, value: y})));
        }
    }, [movementYears, selectedYears.length])

    useEffect(() => {
        if(selectedOperatorYear){
            async function getData(){
                setOperatorLoading(true)
                const response = await getMaclOperatorArrivalsRequest({"Year":selectedOperatorYear.value});
                if(response && response.operatorData) setOperatorData(response.operatorData);
                setOperatorLoading(false)
            }
            getData()
        }
    }, [selectedOperatorYear])

    useEffect(() => {
        if(Object.keys(movementData).length > 0){
            let data = []
            selectedYears?.forEach((y)=>{
                let tempData = Array(12).fill(0)
                movementData[y.value].forEach((d)=>{
                    tempData[d.month-1] = d.total_movement
                });
                data.push({name: y.label, data: tempData})
            })
            setAtmSeriesData(data)
        }
    }, [selectedYears, movementData])


    const options = {
        chart: {
            toolbar: { show: false, },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        xaxis: {
            categories: atmCategories,
        },
        yaxis: { title: { text: "Movements", }, },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
        tooltip: {
            y: {
            formatter: function (val) {
                return val + " Movement";
            },
            },
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-0">
                                <CardBody>
                                    <Loader loading={movementLoading} size="top" />
                                    <h3 align="center">Air Traffic Movements</h3>
                                    <Select options={movementYears.map(y=>({label: y, value: y}))} value={selectedYears} classNamePrefix="select2-selection" isMulti={true} isClearable={false} onChange={(e)=>setSelectedYears(e.map((val)=>({label: val?.label, value: val?.value})))} />
                                    <ReactApexChart options={options} series={atmSeriesData} type="bar" height={350} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="custom-table table mb-0">
                                            <thead>
                                                <tr className="ct-header">
                                                    <th>#</th>
                                                    <th key="operator">Year</th>
                                                    {(atmCategories.map(m=>(<th key={m}>{m}</th>)))}
                                                </tr>
                                            </thead>
                                        <tbody>
                                            {atmSeriesData.map((o, i)=>(
                                                <tr key={i}>
                                                    <th className="ct-left-header" scope="row">{i+1}</th>
                                                    <th className="ct-left-header" scope="row">{o.name}</th>
                                                    {o.data?.map((m, x)=>(<td key={x}>{m ?parseInt(m).toLocaleString() : 0}</td>))}
                                                </tr>   
                                            ))}
                                        </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="mt-5">
                                <CardBody>
                                    <Loader loading={operatorLoading} size="top" />
                                    <h3 align="center">Arrivals by Airline</h3>
                                        <Select options={operatorYears.map(y=>({label: y, value: y}))} value={selectedOperatorYear} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedOperatorYear({label: e.label, value: e.value})} />
                                        <div className="table-responsive">
                                            <Table className="custom-table table mb-0">
                                                <thead>
                                                    <tr className="ct-header">
                                                        <th>#</th>
                                                        <th key="operator">Operator</th>
                                                        {(months.map(m=>(<th key={m}>{m}</th>)))}
                                                        <th key="total">Total</th>
                                                    </tr>
                                                </thead>
                                            <tbody>
                                                {Object.keys(operatorData).map((o, i)=>(
                                                    <tr key={i}>
                                                        <th className="ct-left-header" scope="row">{i+1}</th>
                                                        <th className="ct-left-header" scope="row">{o}</th>
                                                        {operatorData[o].map((m, x)=>(<td key={x}>{m?.["total_arrival"] || 0}</td>))}
                                                    </tr>   
                                                ))}
                                            </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Macl;

