
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getTaxDataRequest } from "../../../api/controller/StatisticsController";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Loader } from "../../../custom/components/Loader";

const TaxYear = () => {

    const [taxLoading, setTaxLoading] = useState(true)
    const [taxData, setTaxData] = useState([])
    const [taxYears, setTaxYears] = useState([])

    const [selectedYears, setSelectedYears] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [metrics] = useState([
        {label: "Departure Tax", value: "total_departure_tax"},
        {label: "Green Tax", value: "total_green_tax"},
        {label: "TGST", value: "total_tgst"},
        {label: "Land Rent", value: "total_land_rent"},
        {label: "Airport Development Fee", value: "total_airport_development_fee"},
        {label: "Total", value: "total"},
    ])
    const [selectedMetric, setSelectedMetric] = useState({label: "Departure Tax", value: "total_departure_tax"})
    const [taxSeriesData, setTaxSeriesData] = useState([])
    const [taxCategories, setTaxCategories] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])
    // eslint-disable-next-line no-unused-vars
    const [months] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])
    const [grandTotal, setGrandTotal] = useState(null)


    useEffect(() => {
        async function getData(){
            const response = await getTaxDataRequest();
            if(response && response.taxData) setTaxData(response.taxData);
            if(response && response.taxYears) setTaxYears(response.taxYears);
            setTaxLoading(false)
        }
        getData();
    }, [])

    useEffect(() => {
        if(selectedYears.length === 0 && taxYears.length > 0){
            setSelectedYears(taxYears.slice(-4).map(y=>({label: y, value: y})))
        }
    }, [taxYears, selectedYears.length])

    useEffect(() => {
        if(Object.keys(taxData).length > 0 && selectedMetric.value && selectedYears.length > 0){
            let data = []
            let tempMonths = []
            let total = 0;
            selectedYears.forEach(y=>{
                data.push({name: y.label, data: taxData[y.value].map(d=>d[selectedMetric.value])})
                tempMonths.push(taxData[y.value].map(d=>d.month))
                total += parseInt(taxData[y.value].map(d=>d["total"]).reduce((a, b) => a + b, 0))
            })
            setGrandTotal(total)
            tempMonths = [...new Set(tempMonths.flat())]
            tempMonths = tempMonths.map(m=>months[m-1])
            tempMonths.sort((a,b) => months.indexOf(a) - months.indexOf(b))
            setTaxCategories(tempMonths)
            setTaxSeriesData(data)
        }
    }, [selectedYears, selectedMetric, months, taxData])


    const options = {
        chart: {
            toolbar: { show: false, },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        xaxis: {
            categories: taxCategories,
        },
        yaxis: { 
            title: { text: "", }, 
            labels: {
                formatter: function (val) {
                    return (val / 1000000).toFixed(0);
                }
            }
        },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val.toLocaleString();
                },
            },
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={taxLoading} size="top" />
                                    <h3 align="center">Tourism Related Tax Collection - {selectedMetric.label} {grandTotal ? ("[Grand Total: $"+grandTotal.toLocaleString()+"]") : ""}</h3>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Select options={taxYears.map(y=>({label: y, value: y}))} value={selectedYears} classNamePrefix="select2-selection" isMulti={true} isClearable={false} onChange={(e)=>setSelectedYears(e.map((val)=>({label: val?.label, value: val?.value})))} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Select options={metrics} value={selectedMetric} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMetric({label: e.label, value: e.value})} />
                                        </Col>
                                    </Row>
                                    <ReactApexChart options={options} series={taxSeriesData} type="bar" height={650} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={taxLoading} size="top" />
                                        <div className="table-responsive">
                                            <Table className="custom-table table mb-0">
                                                <thead>
                                                    <tr className="ct-header">
                                                        <th>#</th>
                                                        <th key="operator">Year</th>
                                                        {(taxCategories.map(m=>(<th key={m}>{m}</th>)))}
                                                        <th key="total">Total</th>
                                                    </tr>
                                                </thead>
                                            <tbody>
                                                {taxSeriesData.map((o, i)=>(
                                                    <tr key={i}>
                                                        <th className="ct-left-header" scope="row">{i+1}</th>
                                                        <th className="ct-left-header" scope="row">{o.name}</th>
                                                        {o.data?.map((m, x)=>(<td key={x}>${m ?parseInt(m).toLocaleString() : 0}</td>))}
                                                        {Array(12 - o.data?.length).fill().map((_, idx) => <td key={`empty-${idx}`}></td>)}
                                                        <td>${o.data?.reduce((a, b) => parseInt(a) + parseInt(b), 0).toLocaleString()}</td>
                                                    </tr>   
                                                ))}
                                            </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default TaxYear;

