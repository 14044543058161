
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getBedDataRequest } from "../../../api/controller/StatisticsController";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Loader } from "../../../custom/components/Loader";

const BedYear = () => {

    const [loading, setLoading] = useState(true)
    const [bedData, setBedData] = useState([])
    const [years, setYears] = useState([])

    const [selectedYears, setSelectedYears] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [metrics] = useState([
        {label: "Resorts", value: "total_resorts"},
        {label: "Hotels", value: "total_hotels"},
        {label: "Guest Houses", value: "total_guest_houses"},
        {label: "Safaris", value: "total_safaris"},
        {label: "Total", value: "total"},
    ])
    const [selectedMetric, setSelectedMetric] = useState({label: "Resorts", value: "total_resorts"})
    const [bedSeriesData, setBedSeriesData] = useState([])
    const [categories, setCategories] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])
    // eslint-disable-next-line no-unused-vars
    const [months] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])

    useEffect(() => {
        async function getData(){
            const response = await getBedDataRequest();
            if(response && response.bedData) setBedData(response.bedData);
            if(response && response.years) setYears(response.years);
            setLoading(false)
        }
        getData();
    }, [])

    useEffect(() => {
        if(selectedYears.length === 0 && years.length > 0){
            setSelectedYears(years.slice(-4).map(y=>({label: y, value: y})))
        }
    }, [years, selectedYears.length])

    useEffect(() => {
        if(Object.keys(bedData).length > 0 && selectedMetric.value && selectedYears.length > 0){
            let data = []
            let tempMonths = []
            selectedYears.forEach(y=>{
                data.push({name: y.label, data: bedData[y.value].map(d=>d[selectedMetric.value])})
                tempMonths.push(bedData[y.value].map(d=>d.month))
            })
            tempMonths = [...new Set(tempMonths.flat())]
            tempMonths = tempMonths.map(m=>months[m-1])
            tempMonths.sort((a,b) => months.indexOf(a) - months.indexOf(b))
            setCategories(tempMonths)
            setBedSeriesData(data)
        }
    }, [selectedYears, selectedMetric, months, bedData])


    const options = {
        chart: {
            toolbar: { show: false, },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        xaxis: {
            categories: categories,
        },
        yaxis: { 
            title: { text: "No.", }, 
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                }
            }
        },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val.toLocaleString();
                },
            },
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                    <h3 align="center">Bed Capacity by Year - {selectedMetric.label}</h3>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Select options={years.map(y=>({label: y, value: y}))} value={selectedYears} classNamePrefix="select2-selection" isMulti={true} isClearable={false} onChange={(e)=>setSelectedYears(e.map((val)=>({label: val?.label, value: val?.value})))} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Select options={metrics} value={selectedMetric} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMetric({label: e.label, value: e.value})} />
                                        </Col>
                                    </Row>
                                    <ReactApexChart options={options} series={bedSeriesData} type="bar" height={650} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                        <div className="table-responsive">
                                            <Table className="custom-table table mb-0">
                                                <thead>
                                                    <tr className="ct-header">
                                                        <th>#</th>
                                                        <th key="operator">Year</th>
                                                        {(categories.map(m=>(<th key={m}>{m}</th>)))}
                                                    </tr>
                                                </thead>
                                            <tbody>
                                                {bedSeriesData.map((o, i)=>(
                                                    <tr key={i}>
                                                        <th className="ct-left-header" scope="row">{i+1}</th>
                                                        <th className="ct-left-header" scope="row">{o.name}</th>
                                                        {o.data?.map((m, x)=>(<td key={x}>{m ?parseInt(m).toLocaleString() : 0}</td>))}
                                                    </tr>   
                                                ))}
                                            </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default BedYear;

